// import * as React from "react";
import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AppContext from "../../context/app-context";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

import "./admin-header.scss";
import MyProfile from "../../pages/profile-page/MyProfile";

export default function AdminHeader() {
  const { setUser, setRefreshToken, setToken } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigator = useNavigate();
  const labels = useSelector((state: any) => state.labels);
  const { label } = labels || { title: "", subTitle: "", isArrowNeed: false };
  const { user } = useContext(AppContext);
  const [openProfilePop, setOpenProfilePop] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenProfile = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setOpenProfilePop(true);
  };

  const handleCloseProfile = () => {
    setOpenProfilePop(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenProfilePop(false);
  };

  const handleLogOut = () => {
    sessionStorage.clear();
    setUser("");
    setRefreshToken("");
    setToken("");
    navigator("/");
  };
  const userImage = user?.image;

  const getImageSrc = () => {
    if (
      userImage &&
      typeof userImage === "object" &&
      "display_image" in userImage
    ) {
      return userImage.display_image;
    }
  };

  const avatarImage = getImageSrc();
  return (
    <>
      <header className="admin-heading">
        {label?.isArrowNeed && (
          <a onClick={() => navigator(-1)}>
            <ArrowBackIcon className="icons" />
          </a>
        )}
        <div>
          <h1 className="m-0">{label?.title}</h1>
          {label?.subTitle && <span> {label?.subTitle}</span>}
        </div>
      </header>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton size="small" sx={{ ml: 2 }}>
          <SearchIcon sx={{ width: 32, height: 32 }} />
        </IconButton>
        <IconButton size="small" sx={{ ml: 2 }}>
          <NotificationsIcon sx={{ width: 32, height: 32 }} />
        </IconButton>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {avatarImage ? (
            <Avatar sx={{ width: 32, height: 32 }} src={avatarImage}></Avatar>
          ) : (
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          )}
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div>
          <MenuItem onClick={handleOpenProfile}>
            <Avatar /> Profile
          </MenuItem>
          <MyProfile
            openProfile={openProfilePop}
            handleClose={handleCloseProfile}
          />
        </div>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
