import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AppProvider from "./context/app-provider";
import { Provider } from "react-redux";
import i18next from "./utils/i18n";
import { I18nextProvider } from "react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { APIProvider } from "@vis.gl/react-google-maps";
import store from "./store/store";

import "./scss/app.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <GoogleOAuthProvider clientId="534290948327-2jev2o9s9amkncvd4t2983vb504e2abq.apps.googleusercontent.com">
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
      {/* <React.StrictMode> */}
      <Provider store={store}>
        <AppProvider>
          <BrowserRouter>
            <I18nextProvider i18n={i18next}>
              <App />
            </I18nextProvider>
          </BrowserRouter>
        </AppProvider>
      </Provider>
      {/* </React.StrictMode> */}
    </APIProvider>
  </GoogleOAuthProvider>,
);
