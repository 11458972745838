import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import "./buttonCounts.scss";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../components/Aleart/AlertMEssage";

type CountsProps = {
  count: any;
  id: string | undefined;
  subId: string | undefined;
};

const ButtonCounts: React.FC<CountsProps> = ({ count, id, subId }) => {
  console.log(count, "mith");
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");

  const handleAddClick = () => {
    if (count?.registered_unit_count >= count?.total_unit_count) {
      setAlertMessage(
        "Unit registration cannot exceed the total available unit count.",
      );
      setAlertSeverity("warning");
      setAlertOpen(true);
    } else {
      if (id && subId) {
        navigate(`/admin/add-new-unit/${id}/${subId}/0`);
      }
    }
  };
  const handleAmenityAddClick = () => {
    if (count?.amenity_count >= count?.total_amenity_count) {
      setAlertMessage(
        "Amenity registration cannot exceed the total available amenity count.",
      );
      setAlertSeverity("warning");
      setAlertOpen(true);
    } else {
      if (id && subId) {
        navigate(`/admin/aminity-regisration/${id}/${subId}`);
      }
    }
  };
  const handleViewClick = () => {
    if (id && subId) {
      navigate(`/admin/unit-list/${id}/${subId}`);
    }
  };
  const handleAmenityViewClick = () => {
    if (id && subId) {
      navigate(`/admin/aminity-regisration/${id}/${subId}`);
    }
  };

  const formatCount = (value: number | undefined): string => {
    return (value ?? 0).toString().padStart(2, "0");
  };

  const isUnitCount = count?.registered_unit_count > 0;
  const isAmenityCount = count?.amenity_count > 0;

  return (
    <div className="counts-container1">
      <Box className="counts-container">
        {/* Center Column */}
        <Box className="counts">
          <div className="count">
            {`${formatCount(count?.registered_unit_count)}/${formatCount(count?.total_unit_count)}`}
          </div>
          <div className="count-name">
            <Trans i18nKey="numberOfUnits">Number of Units</Trans>
          </div>
        </Box>

        {/* Bottom-Right Buttons */}
        <Box className="Bottom-Right-buttons">
          <Button
            className="sml-button-view"
            variant="outlined"
            onClick={handleViewClick}
            disabled={!isUnitCount}
          >
            View
          </Button>
          <Button
            className="sml-button-add"
            variant="contained"
            onClick={handleAddClick}
          >
            Add
          </Button>
        </Box>
      </Box>

      <Box className="counts-container">
        {/* Center Column */}
        <Box className="counts">
          <div className="count">{formatCount(count?.amenity_count)}</div>
          <div className="count-name">
            <Trans i18nKey="numberOfAmenities">Number of Amenities</Trans>
          </div>
        </Box>

        {/* Bottom-Right Buttons */}
        <Box className="Bottom-Right-buttons">
          <Button
            className="sml-button-view"
            variant="outlined"
            disabled={!isAmenityCount}
            onClick={handleAmenityViewClick}
          >
            View
          </Button>
          <Button
            className="sml-button-add"
            variant="contained"
            onClick={handleAmenityAddClick}
          >
            Add
          </Button>
        </Box>
      </Box>
      <AlertMessage
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        severity={alertSeverity}
        message={alertMessage}
      />
    </div>
  );
};

export default ButtonCounts;
