import React from "react";
import { Menu, MenuItem, Divider } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

interface CustomMenuProps {
  parentAnchorEl: null | HTMLElement;
  handleCloseMenu: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
}

const CustomMenu: React.FC<CustomMenuProps> = ({
  parentAnchorEl,
  handleCloseMenu,
  handleEdit,
  handleDelete,
}) => {
  return (
    <Menu
      anchorEl={parentAnchorEl}
      open={Boolean(parentAnchorEl)}
      onClose={handleCloseMenu}
      sx={{
        "& .MuiMenu-paper": {
          width: "auto !important",
          height: "auto !important",
          padding: 0,
          borderRadius: "10px",
          boxShadow: "none",
        },
        "& .MuiMenu-root": {
          cursor: "pointer",
        },
      }}
    >
      <MenuItem
        onClick={handleEdit}
        sx={{
          fontFamily: "Inter, sans-serif",
          fontSize: "12px",
          padding: "0 10px",
          color: "#0C0636",
        }}
      >
        <EditOutlinedIcon sx={{ marginRight: "8px", fontSize: "18px" }} />
        Edit
      </MenuItem>
      <Divider
        sx={{ borderColor: "#E7E6E6", marginLeft: "5px", marginRight: "5px" }}
      />
      <MenuItem
        onClick={handleDelete}
        sx={{
          fontFamily: "Inter, sans-serif",
          fontSize: "12px",
          padding: "0 10px",
          color: "#0C0636",
        }}
      >
        <DeleteOutlinedIcon sx={{ marginRight: "8px", fontSize: "18px" }} />
        Delete
      </MenuItem>
    </Menu>
  );
};

export default CustomMenu;
