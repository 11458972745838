import React, { useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import "./pagination.scss";
import { Trans, useTranslation } from "react-i18next";

interface PaginationProps {
  currentPage: number;
  totalEntries: number;
  entriesPerPage: number;
  onPageChange: (page: number) => void;
  onEntriesPerPageChange: (entries: number) => void;
  isDisabled: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalEntries,
  entriesPerPage,
  onPageChange,
  onEntriesPerPageChange,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const maxPagesToShow = 3;
  let startPage = Math.max(1, currentPage - 1);
  let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

  // Adjust startPage if near the end
  if (endPage === totalPages) {
    startPage = Math.max(1, totalPages - maxPagesToShow + 1);
  }
  const validRange = startPage <= endPage;

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < endPage) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page: number) => {
    onPageChange(page);
  };

  const handleEntriesPerPageChange = (event: SelectChangeEvent<number>) => {
    const entries = Number(event.target.value);
    onEntriesPerPageChange(entries);
    onPageChange(1);

    const targetElement = document.getElementById("dataTable");
    setTimeout(() => {
      targetElement &&
        targetElement?.scrollIntoView({
          behavior: "smooth", // Enables smooth scrolling
          block: "start", // Scroll to the top of the target
        });
    }, 0.001);
  };

  return (
    <Box
      className="pagination-controls"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ gap: 0.1 }}
    >
      {/* Left side: Entries per page dropdown */}
      <Box
        className="entries-per-page"
        display="flex"
        alignItems="center"
        gap={0.2}
      >
        <FormControl className="dropdown" size="small" disabled={isDisabled}>
          <Select
            value={entriesPerPage}
            onChange={handleEntriesPerPageChange}
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  marginTop: "1px",
                  marginRight: "-12px",
                },
              },
              anchorOrigin: {
                vertical: "bottom", // Align the menu to open upwards
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top", // Ensure the menu opens upwards
                horizontal: "center",
              },
            }}
          >
            {[20, 15, 10, 5].map((entries) => (
              <MenuItem
                key={entries}
                value={entries}
                className="MenuItems"
                sx={{
                  borderRadius: "2px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: "#D1D1D1",
                  },
                }}
              >
                {entries}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          fontSize="14px"
          fontFamily="Inter"
          color="#333333"
          fontWeight={400}
          padding="0"
          marginLeft={"6px"}
        >
          <Trans i18nKey="entriesPerPage">entries per page</Trans>
        </Typography>
      </Box>

      {/* Pagination controls */}
      <Box display="flex" alignItems="center" gap={0.75}>
        <Button
          onClick={handlePrevPage}
          disabled={currentPage === 1 || isDisabled}
          className={`pagination-btn ${currentPage === 1 ? "" : "active"}`}
          sx={{
            padding: "0 2px",
            fontSize: "9px",
            minWidth: "auto",
            margin: "0",
          }}
        >
          {"<<"}
        </Button>

        {validRange &&
          [...Array(endPage - startPage + 1)].map((_, idx) => {
            const page = startPage + idx;
            return (
              <Button
                key={page}
                onClick={() => handlePageClick(page)}
                className={currentPage === page ? "active" : ""}
                disabled={isDisabled}
                sx={{
                  width: "14px",
                  minWidth: "14px",
                  height: "14px",
                  padding: 0,
                  fontSize: "9px",
                  textAlign: "center",
                  margin: "0 1px",
                }}
              >
                {page}
              </Button>
            );
          })}

        <Button
          onClick={handleNextPage}
          disabled={currentPage === totalPages || isDisabled}
          className={`pagination-btn ${currentPage === totalPages ? "" : "active"}`}
          sx={{
            padding: "0 2px",
            fontSize: "9px",
            minWidth: "auto",
            margin: "0",
          }}
        >
          {">>"}
        </Button>

        <Typography
          variant="body2"
          className="pagination-info"
          marginLeft={1}
          fontSize="9px"
        >
          <Trans i18nKey="showing">Showing</Trans>{" "}
          {(currentPage - 1) * entriesPerPage + 1}{" "}
          <Trans i18nKey="to">to</Trans>{" "}
          {Math.min(currentPage * entriesPerPage, totalEntries)}
          <Trans i18nKey="of">of</Trans> {totalEntries}{" "}
          <Trans i18nKey="entries">entries</Trans>
        </Typography>
      </Box>
    </Box>
  );
};

export default Pagination;
