import React from "react";

const MinusIcon = ({ width = 19, height = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9.5C19 12.0196 17.9991 14.4359 16.2175 16.2175C14.4359 17.9991 12.0196 19 9.5 19C6.98044 19 4.56408 17.9991 2.78249 16.2175C1.00089 14.4359 0 12.0196 0 9.5C0 6.98044 1.00089 4.56408 2.78249 2.78249C4.56408 1.00089 6.98044 0 9.5 0C12.0196 0 14.4359 1.00089 16.2175 2.78249C17.9991 4.56408 19 6.98044 19 9.5ZM5.34375 8.90625C5.18628 8.90625 5.03525 8.96881 4.92391 9.08016C4.81256 9.19151 4.75 9.34253 4.75 9.5C4.75 9.65747 4.81256 9.8085 4.92391 9.91985C5.03525 10.0312 5.18628 10.0938 5.34375 10.0938H13.6562C13.8137 10.0938 13.9647 10.0312 14.0761 9.91985C14.1874 9.8085 14.25 9.65747 14.25 9.5C14.25 9.34253 14.1874 9.19151 14.0761 9.08016C13.9647 8.96881 13.8137 8.90625 13.6562 8.90625H5.34375Z"
        fill="#C23434"
      />
    </svg>
  );
};

export default MinusIcon;
