import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ParentDetails from "../parent-details/parent-details";
import { useNavigate, useParams } from "react-router-dom";
import WithLandPageView from "../../hoc/hoc-admin";
import ButtonCounts from "./ButtonCounts";
import axios from "axios";
import instance from "../../utils/axios";

type DataItem = {
  id: number;
  title: string;
  value: number;
};

const UnitCounts = () => {
  const [companyCode, setCompanyCode] = useState<any>(null);
  const navigate = useNavigate();
  const { id, subId } = useParams();
  const [data, setData] = useState<DataItem[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleAddClick = () => {
    navigate("/admin/regional-property");
  };

  const fetchData = async () => {
    instance
      .get(`property/getSubPropertiesBysubPropertyId/${subId}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        setError("Error fetching data");
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData(); // Call the fetch function when the component mounts
  }, []);

  return (
    <div>
      <Box>
        <ParentDetails
          dataFetch="property/getManagement/"
          parentCode={id}
          setCode={setCompanyCode}
          handleAddClick={handleAddClick}
          handleEditRout={`/admin/regional-property/${id}`}
          text="Property"
          type="property"
          category="sub"
        />
      </Box>
      <Box display="flex" gap="33px" marginTop="47px">
        {/* {data.length > -1
          ? data.map((item) => <ButtonCounts key={item.id} count={data} />)
          : "no data"} */}
        {/* {data.map((item) => <ButtonCounts key={item.id} count={data} />)} */}
        <ButtonCounts count={data} id={id} subId={subId} />
      </Box>
    </div>
  );
};

export default WithLandPageView(UnitCounts, {
  title: "Prime - Rajagiriya",
  subTitle: "Western Province",
});
