import React from "react";
import { TextField as MuiTextField, FormHelperText, Box } from "@mui/material";

interface TextFieldProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  margin?: string;
  isNumberOfFloorsField?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({
  value,
  onChange,
  error = false,
  helperText = "",
  placeholder,
  margin = "4px",
  isNumberOfFloorsField = false,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        ...(isNumberOfFloorsField && {
          width: "48.5%",
        }),
      }}
    >
      <MuiTextField
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "43px",
            borderRadius: "10px", // Optional: Keep the border radius
            transform: "translateY(-1px)",

            "& fieldset": {
              borderColor: "grey", // Border color
            },
            "& .MuiOutlinedInput-input": {
              color: "#858585",
              fontSize: "14px",
            },
            "&:hover fieldset": {
              borderColor: "grey", // Hover color
            },
            "&.Mui-focused fieldset": {
              borderColor: "#D5D5D5", // Focus color
            },
            "& .MuiInputBase-input": {
              fontFamily: "Inter", // Add font family
              fontSize: "14px", // Add font size
              fontWeight: 400, // Add font weight
              lineHeight: "16.94px", // Add line height
              textAlign: "left", // Align text
              textUnderlinePosition: "from-font", // Add underline position
              textDecorationSkipInk: "none", // Add decoration skip ink
            },
          },
          "& .MuiInputBase-input::placeholder": {
            fontSize: "14px",
            color: "#858585",
          },
          fontFamily: '"Inter", sans-serif',
        }}
      />

      {error && (
        <FormHelperText
          sx={{
            color: "#d32f2f",
            position: "absolute",
            top: "100%",
            marginTop: margin,
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default TextField;
