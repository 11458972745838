import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend) // Use Backend to load translations if needed
  .use(LanguageDetector) // Auto-detect language
  .use(initReactI18next) // React integration
  .init({
    debug: true, // Enable debugging to check what's happening with the translation files
    fallbackLng: "en", // Default language in case the selected one isn't available
    interpolation: {
      escapeValue: false, // React escapes strings by default
    },
    backend: {
      loadPath: "/localization/{{lng}}.json", // Path for language JSON files
    },
    react: {
      useSuspense: false, // Use this if you don't want suspense
    },
  });

export default i18n;
