import React, { useEffect, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import SingleFormControl from "../AddNewSubProperty/components/SingleFormControl";
import { getPropertyTypesByCategory } from "../../services/properties/properties";
import { Trans, useTranslation } from "react-i18next";

interface SelectAmenityProps {
  value: string;
  onChange: (value: {
    id: string;
    description: string;
    displayName: string;
  }) => void;
  error?: boolean;
  helperText?: string;
  typeCategory: string;
}

const SelectAmenity: React.FC<SelectAmenityProps> = ({
  value,
  onChange,
  error = false,
  helperText = "",
  typeCategory,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();
  const [options, setOptions] = useState<
    { id: string; description: string; displayName: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>(value);

  useEffect(() => {
    const fetchOptions = async () => {
      setLoading(true);
      setErrorMessage("");
      try {
        const response = await getPropertyTypesByCategory(typeCategory);
        const mappedOptions = response.data.map((item: any) => ({
          id: item.id,
          description: item.description,
          displayName: item.display_name,
        }));
        setOptions(mappedOptions);

        const matchedOption = mappedOptions.find(
          (option: any) => option.id === value,
        );
        if (matchedOption) {
          setSelectedValue(matchedOption.displayName);
        }
      } catch (err) {
        setErrorMessage("Failed to load options.");
        console.error("Error fetching property types:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [typeCategory, value]);

  const renderMenuItems = () => {
    if (loading) {
      return <MenuItem disabled>Loading...</MenuItem>;
    }

    if (errorMessage) {
      return <MenuItem disabled>{errorMessage}</MenuItem>;
    }

    if (options.length === 0) {
      return (
        <MenuItem disabled>
          <Trans i18nKey="noOptionsAvailable">No options available</Trans>
        </MenuItem>
      );
    }

    return options.map((option) => (
      <MenuItem key={option.id} value={option.displayName}>
        {option.displayName}
      </MenuItem>
    ));
  };

  const handleChange = (e: any) => {
    const selectedOption = options.find(
      (option) => option.displayName === e.target.value,
    );
    if (selectedOption) {
      onChange({
        id: selectedOption.id,
        description: selectedOption.description,
        displayName: selectedOption.displayName,
      });
      setSelectedValue(selectedOption.displayName);
    }
  };

  return (
    <Box>
      <SingleFormControl
        value={selectedValue}
        onChange={handleChange}
        error={error}
        helperText={helperText}
      >
        <MenuItem disabled value="">
          <span style={{ color: "#6b6b6b" }}>Select an Amenity</span>
        </MenuItem>

        {renderMenuItems()}
      </SingleFormControl>
    </Box>
  );
};

export default SelectAmenity;
