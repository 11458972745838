import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Label {
  title: string;
  subTitle: string;
  isArrowNeed?: boolean;
}

// Define the shape of the action payload
interface SetLabelPayload {
  item: Label;
  actionType: string;
}

// Define the initial state
interface LabelState {
  label: Label | null;
  actionType: string;
}

const initialState: LabelState = {
  label: null,
  actionType: "",
};

const labelSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    setLabel(state, action: any) {
      state.label = action.payload;
      state.actionType = action.payload.actionType;
    },
  },
});

export const { setLabel } = labelSlice.actions;
export default labelSlice.reducer;
