import axios from "axios";
import { getToken } from "./utils";

// console.log(AuthContext, "AuthContext 11");

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: { "Content-Type": "application/json", deviceId: "UQ1A.231205.015" },
});

instance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Add token to Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
export default instance;
