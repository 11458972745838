import { Typography } from "@mui/material";
import logoImage from "./../../assets/img/logo.png";
import "./header-right.scss";

interface IHeaderProps {
  title: string;
  tagLine?: string;
  className?: string;
}

const HeaderRight = ({ title, tagLine, className }: IHeaderProps) => {
  return (
    <>
      <div className="header-right">
        <div className="logo">
          <img src={logoImage} alt="Logo" className="logo-img" />
        </div>
        <Typography className={`title ${className}`}>{title}</Typography>
        <Typography className={`sub-title ${className} tagline-f`}>
          {tagLine}
        </Typography>
      </div>
    </>
  );
};

export default HeaderRight;
