import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import ParentDetails from "../../components/parent-details/parent-details";
import AppContext from "../../context/app-context";
import { useNavigate } from "react-router-dom";
import WithLandPageView from "../../hoc/hoc-admin";
import { setLabel } from "../../store/labels/labels";
import { useDispatch } from "react-redux";
import CustomDataTable from "../../components/data-table/data-table";
import AddAmenityForm from "./aminety-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import { deleteAmenity } from "../../services/adminServices";
import instance from "../../utils/axios";
import Pagination from "../../components/pagination/pagination";
const AminityRegisration = () => {
  const [companyCode, setCompanyCode] = useState<string | null>(null);
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const { company_code, subId, id } = useParams();
  const [amenityData, setAmenityData] = useState<any>();
  const dispatch = useDispatch();
  const [selectedAmenity, setSelectedAmenity] = useState<any>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteType, setDeleteType] = useState<"amenity" | null>(null);
  const currentSubPropertyId = subId;
  const tableRef = useRef<any>(null);
  const actionType = "LABEL_CHANGE";

  const handleEditeAmenity = (amenity: any, x: any, item: any) => {
    setSelectedAmenity(() => item);
    setOpenDialog(true);
  };
  const handleNewAmenityAdded = async (newAmenity: any) => {
    handleCloseDialog();
  };

  useEffect(() => {
    dispatch(
      setLabel({
        title: (
          <Box display="flex" alignItems="center">
            <ArrowBackIcon
              style={{ cursor: "pointer", marginRight: "8px" }}
              onClick={() => navigate(-1)}
            />
            Add Amenity
          </Box>
        ),
        subTitle:
          "Add a new property to your portfolio and start managing it efficiently",
      } as any),
    );
  }, [dispatch, company_code]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleDeleteConfirmed = async (
    dataType: string,
    selectedAmenity: any,
  ) => {
    if (dataType === "amenity" && selectedAmenity) {
      try {
        const response = await deleteAmenity(selectedAmenity.id);
        console.log("Successfully deleted amenity:", response);
      } catch (error) {
        console.error("Error deleting amenity:", error);
      }
      setDeleteDialogOpen(false);
      setDeleteType(null);
    }
  };

  const parentDetailsCall = useCallback(() => {
    const navigateOption = (item: any) => {
      dispatch(setLabel({ title: item.name, subTitle: item.state } as any));
    };

    const bodyListElements = (item: any) => (
      <>
        <td className="th-head" onClick={() => navigateOption(item)}>
          {item.propertyType}
        </td>
        <td className="th-head" onClick={() => navigateOption(item)}>
          {item.name}
        </td>
        <td className="th-head" onClick={() => navigateOption(item)}>
          {/* {item.assignee} */}-
        </td>
      </>
    );

    return (
      <CustomDataTable
        ref={tableRef}
        headList={["Amenity Type", "Amenity Name", "Assignee"]}
        bodyList={bodyListElements}
        dataFetch="/property/getAllAmenitiesBySubPropertyId/"
        // data={amenityData}
        handleEditSubRowRecord={handleEditeAmenity}
        title="List of Amenities"
        parentCode={subId || ""}
        type="amenity"
        buttonTitle="Add Amenity"
        deleteType="amenity"
        showButton={true}
        handleDeleteConfirmed={handleDeleteConfirmed}
        handleNavigate={handleOpenDialog}
        disabledPagination={false}
      />
    );
  }, [dispatch]);

  return (
    <Box>
      <Box>
        <ParentDetails
          dataFetch="/property/getManagement/"
          parentCode={company_code}
          setCode={setCompanyCode}
          handleAddClick={() => navigate("/admin/parent-property")}
          handleEditRout={`/admin/regional-property/${id}`}
          text="Property"
          type="property"
          category="amenity"
        />

        {/* Amenities Table */}
        {parentDetailsCall()}
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: "275px",
          right: "10px",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogContent>
            <AddAmenityForm
              open={openDialog}
              onClose={handleCloseDialog}
              initialData={selectedAmenity}
              subPropertyId={currentSubPropertyId || ""}
              onAmenityAdded={handleNewAmenityAdded}
              onAmenityUpdated={handleNewAmenityAdded}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default WithLandPageView(AminityRegisration, {
  title: "Properties",
  subTitle: "",
});
