import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/button/button";
import CustomTextField from "../../components/inputs/text-input";
import instance from "../../utils/axios";
import "./../signup/signup.scss";
import AlertMessage from "../../components/Aleart/AlertMEssage";
import AppContext from "../../context/app-context";
import { setValuesToSession } from "../../utils/utils";
import WithMainHoc from "../../hoc/hoc-main";
import HeaderRight from "../../components/header-right/header-right";
import "./name-collection.scss";

interface FormData {
  firstName: string;
  lastName: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
}

const NameCollection: React.FC = () => {
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { token, setToken, refreshToken, setRefreshToken, setUser }: any =
    useContext(AppContext);
  const location = useLocation();

  const profileData = location.state?.userDetails || {};

  /*
   * State for form data
   */
  const [errors, setErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
  });

  // useEffect(() => {
  // if( token ) { navigate('/admin/properties')}
  // }, [token])

  const validateField = (fieldName: keyof FormData, value: string): string => {
    switch (fieldName) {
      case "firstName":
        if (!value) return "First name is required.";
        break;
      case "lastName":
        if (!value) return "Last name is required.";
        break;
      default:
        break;
    }
    return "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let hasErrors = false;
    let newErrors: FormErrors = {};

    for (const field in formData) {
      const error = validateField(
        field as keyof FormData,
        formData[field as keyof FormData],
      );
      newErrors[field as keyof FormData] = error;
      if (error) {
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setErrors(newErrors);
      return;
    }

    await instance
      .post("/user/googleRegister", {
        email: profileData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        user_type: "admin",
        countryCode: "LK",
        mediaType: "apple",
        apple_id: profileData.userId,
      })
      .then(({ status, data }) => {
        if (status == 200) {
          const { token, refreshToken, isPropertyManager, googleUser } =
            data.data;

          let userDetails = {
            id: googleUser.id,
            first_name: googleUser.first_name,
            last_name: googleUser.last_name,
            email: googleUser.email,
            image: googleUser.Image,
            userRoleId: googleUser.UserRoles[0].id,
          };

          setToken(token);
          setRefreshToken(refreshToken);
          setUser(userDetails);
          setSuccessMessage("Login Successful!");
          setAlertType("success");
          setAlertOpen(true);

          setValuesToSession("token", token);
          setValuesToSession("refreshToken", refreshToken);
          setValuesToSession("user", JSON.stringify(userDetails));

          setTimeout(() => {
            if (isPropertyManager) {
              navigate("/admin/properties");
            } else {
              navigate("/admin/admin-profile-creation");
            }
          }, 3000);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    formField: keyof FormData,
  ) => {
    const error = validateField(formField, e.target.value);

    // Update the error state only for the specific field that triggered onBlur
    setErrors((prevErrors) => ({
      ...prevErrors,
      [formField]: error, // Update only the specific field
    }));
  };

  return (
    <>
      <section className="right-side name-collection">
        <Grid container component={"main"} className="signup-container">
          <Box>
            <HeaderRight
              title="Apple Sign Up"
              tagLine="Welcome to Yohndy - Let’s create your account"
            />
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CustomTextField
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                      onBlur(e, "firstName")
                    }
                    onFocus={(e: any) =>
                      setErrors((prev) => ({ ...prev, firstName: "" }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                      onBlur(e, "lastName")
                    }
                    onFocus={(e: any) =>
                      setErrors((prev) => ({ ...prev, lastName: "" }))
                    }
                  />
                </Grid>
              </Grid>

              <CustomButton
                label="Continue"
                fullWidth
                variant="contained"
                className="sign-in-button"
                type="submit"
              />
            </form>

            <Typography className="create-account">
              Already have an account?{" "}
              <Link to="/login" className="account">
                Sign In
              </Link>
            </Typography>
          </Box>
        </Grid>
        <AlertMessage
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          severity={alertType}
          message={alertType === "success" ? successMessage : errorMessage}
        />
      </section>
    </>
  );
};

export default WithMainHoc(NameCollection);
