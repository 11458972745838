import { IconButton, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

interface QuantityAdjusterProps {
  quantity: number;
  onIncrement: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDecrement: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const QuantityAdjuster: React.FC<QuantityAdjusterProps> = ({
  onIncrement,
  onDecrement,
  quantity,
}) => {
  return (
    <>
      <IconButton
        onClick={onDecrement}
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "8px 0 0 8px",
          backgroundColor: "#f1f5f9",
        }}
      >
        <RemoveIcon />
      </IconButton>
      <Typography sx={{ margin: 2 }}>{quantity}</Typography>
      <IconButton
        onClick={onIncrement}
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "0 8px 8px 0",
          backgroundColor: "#f1f5f9",
        }}
      >
        <AddIcon />
      </IconButton>
    </>
  );
};

export default QuantityAdjuster;
