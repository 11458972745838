import React from "react";

const EditIconCircle = ({ width = 56, height = 55 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="28.5" cy="27.5" rx="28" ry="27.5" fill="#1F00CF" />
      <path
        d="M41.1591 17.2744C41.3168 17.4326 41.4053 17.6468 41.4053 17.8701C41.4053 18.0934 41.3168 18.3076 41.1591 18.4658L39.3991 20.2275L36.0241 16.8525L37.7841 15.0908C37.9424 14.9326 38.1569 14.8438 38.3807 14.8438C38.6044 14.8438 38.819 14.9326 38.9772 15.0908L41.1591 17.2727V17.2744ZM38.206 21.4189L34.831 18.0439L23.3341 29.5425C23.2412 29.6354 23.1713 29.7486 23.1299 29.8733L21.7715 33.9469C21.7468 34.0212 21.7433 34.1008 21.7614 34.177C21.7794 34.2531 21.8182 34.3227 21.8736 34.3781C21.9289 34.4334 21.9985 34.4722 22.0747 34.4903C22.1508 34.5083 22.2304 34.5048 22.3047 34.4802L26.3783 33.1217C26.5028 33.0808 26.6161 33.0115 26.7091 32.9192L38.206 21.4189Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6875 36.7812C16.6875 37.4526 16.9542 38.0964 17.4289 38.5711C17.9036 39.0458 18.5474 39.3125 19.2188 39.3125H37.7812C38.4526 39.3125 39.0964 39.0458 39.5711 38.5711C40.0458 38.0964 40.3125 37.4526 40.3125 36.7812V26.6562C40.3125 26.4325 40.2236 26.2179 40.0654 26.0596C39.9071 25.9014 39.6925 25.8125 39.4688 25.8125C39.245 25.8125 39.0304 25.9014 38.8721 26.0596C38.7139 26.2179 38.625 26.4325 38.625 26.6562V36.7812C38.625 37.005 38.5361 37.2196 38.3779 37.3779C38.2196 37.5361 38.005 37.625 37.7812 37.625H19.2188C18.995 37.625 18.7804 37.5361 18.6221 37.3779C18.4639 37.2196 18.375 37.005 18.375 36.7812V18.2188C18.375 17.995 18.4639 17.7804 18.6221 17.6221C18.7804 17.4639 18.995 17.375 19.2188 17.375H30.1875C30.4113 17.375 30.6259 17.2861 30.7841 17.1279C30.9424 16.9696 31.0312 16.755 31.0312 16.5312C31.0312 16.3075 30.9424 16.0929 30.7841 15.9346C30.6259 15.7764 30.4113 15.6875 30.1875 15.6875H19.2188C18.5474 15.6875 17.9036 15.9542 17.4289 16.4289C16.9542 16.9036 16.6875 17.5474 16.6875 18.2188V36.7812Z"
        fill="white"
      />
    </svg>
  );
};

export default EditIconCircle;
