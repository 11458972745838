import React from "react";
interface DeleteIconProps {
  height: number;
  width: number;
  padding?: string | number;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
const DeleteIcon: React.FC<DeleteIconProps> = ({
  height,
  width,
  onClick,
  padding,
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: padding || "0px",
        }}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="black"
          viewBox="0 0 17 17"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      </div>
    </div>
  );
};

export default DeleteIcon;
